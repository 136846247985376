import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, TextField } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import '../../styles.css';

// import { loadStripe } from '@stripe/stripe-js';
import StripeCheckout from 'react-stripe-checkout'
// const stripePromise = loadStripe('');
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'sweetalert2/dist/sweetalert2.min.css';

const MySwal = withReactContent(Swal);

const ConversionRate = 0.5;
const Checkout = () => {


    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [topupAmount, setTopupAmount] = useState('');
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [stripe, setStripe] = useState(null); // State for the stripe object
    const [errors, setErrors] = useState({});


    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        // Validate password
        if (!topupAmount) {
            formIsValid = false;
            errors["topupAmount"] = "Please enter amount to topup";
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setTopupAmount(''); // Reset the amount input value when closing the popup
        setConvertedAmount(0);
    };

    const handleSuccess = () => {
        MySwal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Your topup was successful!',
            customClass: {
                container: 'my-swal-container',
                popup: 'my-swal-popup',
            },
        })
    }

    const handleError = () => {
        MySwal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Your topup was not successful!',
            customClass: {
                container: 'my-swal-container',
                popup: 'my-swal-popup',
            },
        })
    }

    const handleTopup = async token => {
        if (validateForm()) {
            try {
                const response = await axios({
                    url: "https://api.bosskids.net:7000/api/v1.1/wallet/topup",
                    method: "POST",
                    data: {
                        amount: topupAmount * 100,
                        token,
                        walletAddress: userWalletAddress, // Use the userWalletAddress obtained from handleGetAddress
                    }
                });


                if (response.status === 200) {
                    handleSuccess();
                }
            } catch (error) {
                handleError();
            }
        }

    };


    const handleAmountChange = (e) => {
        const amount = e.target.value;
        setTopupAmount(amount);

        if (amount) {
            const converted = parseFloat(amount) / ConversionRate;
            setConvertedAmount(converted.toFixed(2)); // Convert and round the amount to 2 decimal places
        } else {
            setConvertedAmount(amount);
        }
    };

    const [userWalletAddress, setUserWalletAddress] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();
    const [artwork, setArtwork] = useState(null);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        const userId = localStorage.getItem('userId');

        fetch(`https://api.bosskids.net:7000/api/artworks/artwork/${id}`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setArtwork(data);
                setTo(data.walletAddress);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [id]);

    const handleBack = () => {
        navigate(-1);
    };

    const handlePay = () => {
        const paymentData = {
            from,
            to,
            amount: artwork.price,
        };
        fetch(`https://api.bosskids.net:7000/api/v1.1/wallet/pay/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Include any required headers, such as authorization headers with the token
            },
            body: JSON.stringify(paymentData),
        })
            .then((response) => response.json())
            .then((data) => {

                // Log the data to the console for debugging purposes
                console.log(data);

                if (data.status === 'success') {
                    // Display success toast message
                    toast.success(`Payment successful for "${artwork.title}"`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                } else {
                    // Display error toast message with the specific error message
                    toast.info(data.message, {
                        position: 'top-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            })
            .catch((err) => {
                // Log the error to the console for debugging purposes
                console.error(err);

                // Display error toast message
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            });
    }

    const handleGetAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.id;
                const userType = decodedToken.role;

                let typeOfUser;
                if (userType === 'child01') {
                    typeOfUser = 'child';
                } else if (userType === 'parent01') {
                    typeOfUser = 'parent';
                } else if (userType === 'customer01') {
                    typeOfUser = 'customer';
                } else {
                    console.error('Invalid user role:', userType);
                    return;
                }

                // Use the userId and typeOfUser variables to construct the endpoint

                const url = `https://api.bosskids.net:7000/api/accounts/account/${userId}/wallet/${typeOfUser}`;
                const response = await axios.get(url);

                // Store the result in a variable
                const result = response.data;

                // Do something with the result
                console.log('Wallet address:', result);
                setUserWalletAddress(result.walletAddress);
                setFrom(result.walletAddress);
            }
        } catch (error) {
            console.error('Error fetching wallet address:', error);
        }
    };

    if (!artwork) {
        return <div>Loading...</div>;
    }

    // Add the following line below the above code block
    console.log('Price data type:', typeof artwork.price);
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card sx={{ maxWidth: 600 }}>
                <CardMedia component="img" height="200" image={`https://api.bosskids.net:7000/${artwork.image_url}`} alt={artwork.title} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {artwork.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {artwork.description}
                    </Typography>
                    <Typography variant="body2" color="secondary" sx={{ fontWeight: 'bold' }}>
                        Price: <Box component="span" sx={{ fontWeight: 'semibold' }}>BK{artwork.price}</Box>
                    </Typography>

                    <TextField
                        label="Send from"
                        fullWidth
                        margin="normal"
                        type="text"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleGetAddress}>Get Address</Button>
                    <TextField
                        type="text"
                        placeholder="To"
                        value={to}
                        label="Send To"
                        fullWidth
                        margin="normal"
                    />

                    <TextField
                        type="text"
                        value={artwork.price}
                        label="Amount in BK"
                        fullWidth
                        margin="normal"
                    />
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="small" onClick={handleBack}>Back</Button>
                    <Button size="small" onClick={handlePay}>Pay</Button>
                    <Button size="small" variant='contained' onClick={handleOpenPopup}>Topup</Button>
                </CardActions>


                <Dialog open={isPopupOpen} onClose={handleClosePopup}>
                    <DialogTitle>Topup</DialogTitle>

                    <DialogContent>
                        <Typography>1BK = $0.5</Typography>
                        <TextField
                            label="Amount"
                            fullWidth
                            margin="normal"
                            type="number"
                            value={topupAmount}
                            //     onChange={handleAmountChange}
                            // onChange={(e) => setTopupAmount(e.target.value)}
                            onChange={(e) => {
                                handleAmountChange(e);
                                setTopupAmount(e.target.value);
                            }}
                            error={errors.topupAmount ? true : false}
                            helperText={errors.topupAmount}
                        />
                        <Typography>You will receive: <span style={{ color: 'green', fontWeight: '800' }}>BK</span> <span style={{ color: 'green', fontWeight: '600' }}>{convertedAmount}</span></Typography>
                        <StripeCheckout
                            stripeKey={process.env.REACT_APP_PUBLISHABLE_KEY}
                            label="Topup with card"
                            name="Topup with credit card"
                            billingAddress
                            shippingAddress
                            amount={topupAmount}
                            description='Buy BK coins'
                            token={handleTopup} // Pass the callback function as the token prop

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup}>Cancel</Button>
                        {/* <Button onClick={handleTopup} variant="contained">Deposit</Button> Add the "Topup" button inside the popup card */}
                    </DialogActions>
                </Dialog>
            </Card>
            <ToastContainer
                // theme="dark"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Box>
    );
};

export default Checkout;
