import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Card, CardContent, } from "@mui/material";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import AddIcon from "@mui/icons-material/Add";
const Artworks = ({ childId }) => {
    const [title, setTitle] = useState("");
    const [image_url, setImage_url] = useState(null);
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");

    const [accountStatus, setAccountStatus] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const childID = decodedToken.id;
                    const userType = decodedToken.role;

                    let typeOfUser;
                    if (userType === 'child01') {
                        typeOfUser = 'child';
                    }

                    const response = await axios.get(
                        `https://api.bosskids.net:7000/api/accounts/account/${childID}/accountDetails/${typeOfUser}`
                    );
                    const responseData = response.data;
                    setAccountStatus(responseData.account.status); // Update the account status
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("image_url", image_url);
        formData.append("description", description);
        formData.append("price", price);

        try {
            const childToken = sessionStorage.getItem('token');
            const parenToken = localStorage.getItem('token');
            if (parenToken || childToken) {
                const decodedToken = jwtDecode(parenToken);
                const parentId = decodedToken.id;
                console.log('Parent ID:', parentId);

                const decodedChildToken = jwtDecode(childToken);
                const childId = decodedChildToken.id;
                console.log('Child ID:', childId);
                if (accountStatus === 1) {
                    await axios.post(`https://api.bosskids.net:7000/api/artworks/child/${childId}/artwork/${parentId}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("token")} `,
                        },
                    });
                    toast.success("Artwork created successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        window.location.href = "/my/arts";
                    }, 2000); // Delay the redirection for the same duration as the autoClose time
                } else if (accountStatus === 2 || accountStatus === -1) {
                    toast.info("Wait for your account to be active before posting an artwork.", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to create art", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <Card sx={{ maxWidth: 600 }}>
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <TextField
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            variant="outlined"
                            required
                        />
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined"
                            required
                        />
                        <TextField
                            type="file"
                            onChange={(e) => setImage_url(e.target.files[0])}
                            required
                        />
                        <TextField
                            label="Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    <Box sx={{ marginTop: 4 }}>
                        <Button variant="contained" onClick={handleSubmit} fullWidth>
                            Post
                        </Button>
                    </Box>

                </CardContent>
            </Card>
            <ToastContainer
                // theme="dark"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Box>

    );
}
export default Artworks;