import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    Grid,
    Typography,
    Link,
    Box,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const Logo = require("../../BosskidsLogo.jpg")

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        // Validate email
        if (!email) {
            formIsValid = false;
            errors["email"] = "Please enter your email address";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formIsValid = false;
            errors["email"] = "Please enter a valid email address";
        }

        // Validate password
        if (!password) {
            formIsValid = false;
            errors["password"] = "Please enter your password";
        } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/)) {
            formIsValid = false;
            errors["password"] = "Please enter a strong password. It should contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character. e.g Password_500$'";
        }



        setErrors(errors);
        return formIsValid;
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            try {
                const response = await axios.post("https://api.bosskids.net:7000/api/parent/login", formData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    // Extract token from response
                    // localStorage.setItem("token", response.data.token);

                    const token = response.data.token;

                    // Save token in local storage
                    localStorage.setItem("token", token);

                    toast.success("Logged in successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        window.location.href = "/arts&crafts";
                    }, 2000); // Delay the redirection for the same duration as the autoClose time
                } else {
                    alert("Failed to log in");
                }
            } catch (error) {
                console.error(error);
                toast.error(error.response.data.message, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    };



    return (
        <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Card>
                    <CardHeader

                        title={
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: '#00A86B' }}>
                                Parent Login
                            </Typography>
                        }
                        alignItems="center"
                    />


                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={Logo} alt="Logo" style={{ width: '150px', height: '150px' }} />
                    </div>
                    <CardContent>
                        <form onSubmit={handleLogin}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        variant="outlined"
                                        value={email}
                                        onChange={(mail) => setEmail(mail.target.value)}
                                        error={errors.email ? true : false}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(pass) => setPassword(pass.target.value)}
                                        error={errors.password ? true : false}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handlePasswordToggle}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#00A86B',
                                        }}
                                    >
                                        Login
                                    </Button>
                                    <Box></Box>
                                    <Typography align="center">
                                        Don't have an account?
                                        <Link href="/parent/register">
                                            Create account
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <ToastContainer
                                // theme="dark"
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Login;
