import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Box,
    TextField,
    InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Avatar } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit } from '@material-ui/icons';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100vh',
        paddingLeft: '200px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
        },

    },
}));


const Children = () => {
    const [name, setName] = useState("")
    const [age, setAge] = useState("")
    const [userProfilePhoto, setUserProfilePhoto] = useState(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState(null);

    const classes = useStyles();

    const [selectedParent, setSelectedParent] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const [children, setChildren] = useState([])

    const [selectedChild, setSelectedChild] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleOpenEdit = (child) => {
        setSelectedChild(child);
        setIsEditModalOpen(true);
    };

    const handleCloseEdit = () => {
        setIsEditModalOpen(false);
    };


    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        // Validate password
        if (!password) {
            formIsValid = false;
            errors["password"] = "Please enter your password";
        } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/)) {
            formIsValid = false;
            errors["password"] = "Please enter a strong password. It should contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character. e.g Password_500$'";
        }


        // Validate name
        if (!name) {
            formIsValid = false;
            errors["name"] = "Please enter your name";
        }

        setErrors(errors);
        return formIsValid;
    };


    const handleSaveEdit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", selectedChild.name);
        formData.append("age", selectedChild.age);
        formData.append("userProfilePhoto", selectedChild.userProfilePhoto);

        try {
            const response = await axios.put(
                `https://api.bosskids.net:7000/api/child/update/${selectedChild.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        // Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            console.log(response);

            toast.success("Child information updated successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            window.location.reload();
        } catch (error) {
            console.error(error);

            toast.error("Failed to update child account.", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        handleCloseEdit();
    };


    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const parentId = decodedToken.id;
                    const userType = decodedToken.role;

                    let typeOfUser;
                    if (userType === 'parent01') {
                        typeOfUser = 'parent';
                    }
                    console.log(typeOfUser);
                    // Use the parentId variable as needed
                    console.log('Parent ID:', parentId);
                    const response = await axios.get(
                        `https://api.bosskids.net:7000/api/accounts/account/${parentId}/accountDetails/${typeOfUser}`
                    );
                    const responseData = response.data;
                    console.log(responseData);
                    setFormData(responseData);

                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();
        // Validate the form
        const formIsValid = validateForm();

        if (formIsValid) {
            if (formData.account.status === 1) {
                const form = new FormData();
                form.append("name", name);
                form.append("age", age);
                form.append("userProfilePhoto", userProfilePhoto);
                form.append("email", email);
                form.append("password", password);

                try {
                    const token = localStorage.getItem('token');
                    if (token) {
                        const decodedToken = jwtDecode(token);
                        const parentId = decodedToken.id;
                        const userType = decodedToken.role;

                        let typeOfUser;
                        if (userType === 'parent01') {
                            typeOfUser = 'parent';
                        }
                        console.log(typeOfUser)
                        // Use the parentId variable as needed
                        console.log('Parent ID:', parentId);
                        await axios.post(`https://api.bosskids.net:7000/api/child/parent/${parentId}`, form, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${localStorage.getItem("token")} `,
                            },
                        });

                        toast.success("Child onboarded successfully!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setIsOpen(false);
                        window.location.reload();
                        // window.location.href = `/send/request/${accountId}`;
                    }
                } catch (error) {
                    console.error(error);
                    toast.error("Failed to onboard a child.", {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            else if (formData.account.status === 2 || formData.account.status === -1) {
                toast.info("Wait for your account to be active before onboarding a child.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    useEffect(() => {
        const getMyChildren = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const userId = decodedToken.id;
                    // Use the userId variable as needed
                    console.log('Parent ID:', userId);

                    const response = await fetch(`https://api.bosskids.net:7000/api/child/children/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    console.log(response)

                    if (response.ok) {
                        const data = await response.json();
                        console.log(data);
                        setChildren(data);

                    } else {
                        console.log('Failed to retriev your children:', response.status);
                    }
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        getMyChildren();

    }, []);


    return (

        <TableContainer component={Paper} className={classes.gridContainer}>
            <Table>
                {children.length > 0 && (
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: "800", fontSize: "18px" }}>ID</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Profile Photo</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Name</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Age</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Created At</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Updated At</TableCell>
                            <TableCell style={{ fontWeight: "bolder", fontSize: "18px" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {children.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={7} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                You haven't onboarded any child yet
                            </TableCell>
                        </TableRow>
                    ) : (
                        children.map((child) => (
                            <TableRow key={child.id}>
                                <TableCell>{child.id}</TableCell>
                                <TableCell style={{ width: '15%', textAlign: 'center' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        {child.userProfilePhoto ? (
                                            <img
                                                alt={child.name}
                                                src={`https://api.bosskids.net:7000/${child.userProfilePhoto}`}
                                                style={{
                                                    width: '3vw',
                                                    height: '45px',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        ) : (
                                            <Avatar></Avatar>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell>{child.name}</TableCell>
                                <TableCell>{child.age}</TableCell>
                                <TableCell>{child.createdAt}</TableCell>
                                <TableCell>{child.updatedAt}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleOpenEdit(child)}>
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>

            </Table>
            <ToastContainer
                // theme="dark"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Dialog open={isEditModalOpen} onClose={handleCloseEdit}>
                <DialogContent>
                    <Card>
                        <CardHeader title="Child Information" />
                        <CardContent>
                            <TextField
                                fullWidth
                                label="Name of child"
                                name="name"
                                value={selectedChild?.name || ""}
                                onChange={(e) => {
                                    setSelectedChild((prevChild) => ({
                                        ...prevChild,
                                        name: e.target.value,
                                    }));
                                }}
                                margin="normal"
                                variant="outlined"
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Age"
                                name="Age"
                                value={selectedChild?.age || ""}
                                onChange={(e) => {
                                    setSelectedChild((prevChild) => ({
                                        ...prevChild,
                                        age: e.target.value,
                                    }));
                                }}
                                margin="normal"
                                variant="outlined"
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                type="file"
                                name="userProfilePhoto"
                                onChange={(e) => {
                                    setSelectedChild((prevChild) => ({
                                        ...prevChild,
                                        userProfilePhoto: e.target.files[0],
                                    }));
                                }}
                                margin="normal"
                                variant="outlined"
                                sx={{ mb: 2 }}
                            />
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleCloseEdit} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveEdit} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>

            </Dialog>
            <div style={{ paddingLeft: "200px" }}>
                <div style={{ position: 'fixed', top: '80px', right: '10px' }}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Onboard Child
                    </Button>
                </div>
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>
                        <Box>
                            <Typography variant="h6">Only parents can create a child's account</Typography>

                        </Box>

                    </DialogTitle>
                    <DialogContent>
                        <Card>
                            <CardHeader title="Child Information" />
                            <CardContent>
                                <TextField
                                    fullWidth
                                    label="Name of child"
                                    name="name"
                                    value={name}
                                    onChange={(name) => setName(name.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Age"
                                    name="Age"
                                    value={age}
                                    onChange={(age) => setAge(age.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                                <Typography>Profile Photo</Typography>
                                <TextField
                                    fullWidth
                                    type="file"
                                    variant='outlined'
                                    onChange={(pic) => setUserProfilePhoto(pic.target.files[0])}
                                    sx={{ mb: 2 }}
                                    required
                                />
                                <TextField
                                    style={{ marginTop: 6 }}
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(pass) => setPassword(pass.target.value)}
                                    error={errors.password ? true : false}
                                    helperText={errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handlePasswordToggle}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleSave} variant="contained" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >


        </TableContainer>


    );
}

export default Children;
