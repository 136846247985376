import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Grid, Link } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const MyArts = () => {
    const navigate = useNavigate();

    const [artworks, setArtworks] = useState([]);

    useEffect(() => {
        const childToken = sessionStorage.getItem('token');
        if (childToken) {
            const decodedChildToken = jwtDecode(childToken);
            const childId = decodedChildToken.id;
            console.log('Child ID:', childId);
            fetch(`https://api.bosskids.net:7000/api/artworks/childarts/${childId}`, {
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setArtworks(data);
                })

                .catch((err) => {
                    console.log(err.message);
                });
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // height: '100vh',
                flexWrap: 'wrap',
                paddingLeft: { xs: 0, sm: '200px' },
            }}
        >
            {artworks.length === 0 ? (
                <Typography variant="body1" sx={{ fontSize: 20 }}>
                    You haven't posted any artwork yet!
                    <Link href="/child/login">
                        Start posting
                    </Link>
                </Typography>
            ) : (
                artworks.map((artwork) => (
                    <Card sx={{ maxWidth: 345, m: 2, width: '100%', height: '100%' }} key={artwork.id}>
                        <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                            <CardMedia
                                component="img"
                                image={`https://api.bosskids.net:7000/${artwork.image_url}`}
                                alt={artwork.title}
                                sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            />
                        </Box>
                        <CardContent sx={{ height: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {artwork.title}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {artwork.description}
                            </Typography>
                            <Typography variant="body2" color="secondary" sx={{ fontWeight: 'bold' }}>
                                Price: <Box component="span" sx={{ fontWeight: 'semibold' }}>BK {artwork.price}</Box>
                            </Typography>
                        </CardContent>
                    </Card>
                ))
            )}

            <ToastContainer
                // theme="dark"
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Box>

    );
}

export default MyArts;
