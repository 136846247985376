import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100vh',
        paddingLeft: '200px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
        },

    },
}));

const WithdrawForm = () => {

    const classes = useStyles();

    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [destination, setDestination] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://api.bosskids.net:7000/api/customer/withdraw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount, walletAddress, destination }),
            });

            if (response.ok) {
                // Withdrawal successful
                setError('');
                setAmount('');
                setWalletAddress('');
                setDestination('');
                alert('Withdrawal successful');
            } else {
                const data = await response.json();
                setError(data.message || 'Withdrawal failed');
            }
        } catch (error) {
            setError('Withdrawal failed');
        }

        setLoading(false);
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', paddingLeft: { xs: 0, sm: '200px' } }} className={classes.gridContainer}>
            <form onSubmit={handleSubmit} >
                <TextField
                    label="Amount"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                    fullWidth
                />

                <TextField
                    label="Stripe Payout Destination"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    required
                    fullWidth
                    style={{ marginTop: '16px' }}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Withdraw'}
                </Button>

                {error && <p>{error}</p>}
            </form >
        </Box>
    );
};

export default WithdrawForm;
